<template>
  <div class="contents">
  <div class="container-fluid">
    <div class="profile-content mb-50">
      <div class="row">
        <div class="col-lg-12 mb-2 sticky">
          <div class="breadcrumb-main p-3" style="background-color: #f4f5f7 !important;">
            <h4 class="text-capitalize breadcrumb-title">{{$route.params.user}}
              <ul class="atbd-breadcrumb nav">
                  <li class="atbd-breadcrumb__item">
                      <router-link to="/">
                          <span class="la la-home"></span>
                      </router-link>
                      <span class="breadcrumb__seperator">
                          <span class="la la-slash bread-item"></span>
                      </span>
                  </li>
                  <li class="atbd-breadcrumb__item">
                      <span class="bread-item">Users</span>
                  </li>
              </ul>
            </h4>
            <div class="breadcrumb-action justify-content-center flex-wrap">
            </div>
          </div>
        </div>
        <!-- Data Tables and Certifications Ends -->
        <div class="container-fluid" style="position: relative">
           <loader-overlay :active="true" v-if="!asyncLoader && loadingUser" :zIndex="99" position="absolute" :height="80"/>
            
          <div class="row">
            <div class="col-lg-12">
              <div 
                class="userDatatable orderDatatable sellerDatatable global-shadow border mb-30 py-30 px-sm-30 px-20 bg-white radius-xl w-100">
                <div class="project-top-wrapper d-flex justify-content-between flex-wrap mb-25 mt-n10" >
                  <div class="d-flex align-items-center flex-wrap justify-content-center">
                    <div class="project-search mt-0 mr-2 order-search  global-shadow">
                      <form action="" class="order-search__form" @submit.prevent="findUser">
                        <span data-feather="search"></span>
                        <input class="form-control mr-sm-2 border-0 box-shadow-none" type="search"  v-model="username"
                          placeholder="Search by username" aria-label="Search"> 
                      </form>
                    </div>
                    <button class="btn btn-primary ml-3 rounded">Search</button>
                  </div>
                </div><no-content v-if="noData"/>

                <div class="table-responsive" v-if="users && !noData">
                  <table class="table mb-0 table-borderless border-0">
                    <thead>
                      <tr class="userDatatable-header">
                        <th scope="col">
                          <span class="userDatatable-title">Name</span>
                        </th>
                        <th scope="col">
                          <span class="userDatatable-title">Email</span>
                        </th>
                        <th scope="col">
                          <span class="userDatatable-title">Phone Number</span>
                        </th>
                        <th scope="col">
                          <span class="userDatatable-title"> Registered On</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-for="user in users" :key="user.ulid" class="t-row">
                        <td>
                          <div class="d-flex align-items-center">

                            <div class="orderDatatable-title">
                              <p class="d-block mb-0">
                                <router-link :to="{name: 'users-profile', params:{ulid: user.ulid}}"> {{ `${user.fname} ${user.lname}`}} </router-link>
                              </p>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="orderDatatable-title">
                            {{user.email}}
                          </div>
                        </td>
                        <td>
                          <div class="orderDatatable-title">
                            {{user.mobile}}
                          </div>
                        </td>
                        <td>
                          <div class="orderDatatable-title">
                            {{user.added | date}}
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              
            </div>
          </div>
        </div>
     
      </div>
         <Pagination :url="userUrl" @pageChange="pageChange" @loading="loading" :size="size" />
    </div>
  </div>
</div>
</template>

<script>
import { apiClient } from '@/plugins/restclient';
import { getTimestamp } from "@/utilities";

import LoaderOverlay from "@/components/LoaderOverlay";
import NoContent from '@/components/NoContent.vue';
import Pagination from '@/components/Pagination';
import { mapState } from 'vuex';

export default {
    components:{
      LoaderOverlay,
      NoContent,
      Pagination
    },
    data(){
        return{
            size: 10,
            users: [],
            totalPage: 0,
            loadingUser: false,
            username:'',
            userUrl: '',
            noData: false,
            recordSet: []
        }
    },
    computed:{
      ...mapState(['asyncLoader'])
    },
    created(){
      this.loadUrl()
    },
    watch:{
      username(newval){
        if(!newval){
          this.users = this.recordSet
          this.noData = false
        }
      },
      
      '$route' () {
          this.loadUrl()
        }
    },
     metaInfo: {
    title: 'Greater Favour',
    titleTemplate: '%s | Profile',
  },
    methods:{
      loadUrl(){
        const userRoleMap = {
          guardians: 2,
          students: 1
        }
        this.userUrl = '/v1/office/operation/users/fetch/role/' + userRoleMap[this.$route.params.user]
      },
      pageChange(dataSet){
        this.users = dataSet
        this.recordSet = dataSet
        if(this.users.length == 0){
          this.noData = true
        }else{
          this.noData = false
        }
      },
      loading(status){
        this.loadingUser = status
      },
      findUser(){
        
        if(!this.username.trim().length) return

        this.loadingUser = true
        apiClient.get(`/v1/office/operation/users/user/${this.username}`,{
           headers:{
                  "X-Timestamp": getTimestamp(),
                  "X-Ulid": `${this.$store.state.user.ulid}`,
              }
        })
        .then(res =>{
          this.users = [res.data.info.user]
          this.loadingUser = false
        })
        .catch(() => {
          this.loadingUser = false
          this.noData = true
        })
      },
    },
    beforeRouteEnter(to, from, next){
      const pages = ['students', 'guardians']
      if(pages.indexOf(to.params.user)<0){
        return
      }
      next()
    },
}
</script>

<style>
.rounded{
  border-radius: 30px !important;
  padding: 0px 38px;
}
.t-row{
  height: 50px;
}
.swdt {
    width: 65%;
}
.search-icon{
  position: relative;
    right: -35px;

}
.search-input{
  padding-left: 35px;
}
.bread-item:hover {
    color: #9299b8 !important;
}
</style>
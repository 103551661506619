<template>
      <div class="col-md-12 d-flex justify-content-end">
        <form @submit.prevent="goto" class="d-flex align-items-start py-3" v-if="totalPage>1">
        <span class="pt-3">Page</span> 
        <input type="number" placeholder="1" min="1" :max="this.totalPage" class="form-control mx-2 p-4 go-input" v-model.number="pInput" /> <span class="pt-3 mr-2">of {{this.totalPage}}</span> 
        <button type="submit" class="btn btn-primary go-btn">Go</button>
        </form>
        <paginate v-if="totalPage>1" :page-count="totalPage" :click-handler="loadNext" 
          v-model="page"
          :prev-text="prevText"
          :next-text="nextText"
          :hide-prev-next="true"
          :container-class="'atbd-pagination d-flex justify-content-end py-3'"
          :page-class="'atbd-pagination__item'" :page-link-class="'atbd-pagination__link'"
          :prev-link-class="'atbd-pagination__link pagination-control'"
          :next-link-class="'atbd-pagination__link pagination-control'">
        </paginate>
      </div>
    
</template>
<script>
import { apiClient } from "@/plugins/restclient";
import { getTimestamp } from "@/utilities";
import Paginate from "vuejs-paginate";
export default {
    components:{
        Paginate,
    },
    props:['size', 'url', 'tag', 'urlParam'],
    data(){
        return{
            prevText: '<span class="la la-angle-left"></span>',
            nextText: '<span class="la la-angle-right"></span>',
            dataSet: '',
            totalPage: '',
            page: 1,
            pInput: 1
        }
    },
    created(){
      this.loadNext(1)
    },
    watch:{
      url(){
        this.loadNext(1)
      },
      urlParam(){
        this.loadNext(1)
        this.page = 1
      },
      size(){
        this.getRecords(this.page)
      }
    },
    methods:{
      goto(){
        this.page = this.pInput
        this.getRecords(this.page)
      },
        loadNext(num) {
        this.getRecords(num);
        return;
      },
      getRecords(page) {
        this.$emit('loading', true)
        const urlParam = this.urlParam ? `${this.urlParam}`: '' 

        const offset = (page-1)*this.size

        apiClient.get(`${this.url}?offset=${(page-1)*this.size}&size=${this.size}${urlParam}`, {
            headers: {
              "X-Timestamp": getTimestamp(),
              "X-Ulid": `${this.$store.state.user.ulid}`,
            }
          })
          .then(res => {
            this.currentPage = Number(page);
            this.xPage = "";
            this.totalRecords = res.data.info.total
            this.tag ? this.dataSet = res.data.info[this.tag] : this.dataSet = res.data.data
            const paginationData = {
              totalRecords: this.totalRecords,
              offset
            }
            const response = res.data
            this.$emit('pageChange', this.dataSet, paginationData, response )
            this.$emit('loading', false)
            this.totalPage = Math.ceil(res.data.info.total / this.size);
          })
          .catch((err) => {
            this.$emit('loading', false)
            this.$emit('pageChange',[], [], {error: true, err})
          });
      },
    }
    
}
</script>
<style>
.go-btn{
      padding: 2px 16px;
    margin-top: 1px;
    margin-right: 30px;
    background: #fde0d0;
    color: #d94f03;
    border: none;

}
.go-input{
    width: 100px;
    padding-left: 10px !important;
    padding-right: 10px !important;
}
.atbd-pagination__link.pagination-control span{
  font-size: 19px !important;
}
</style> 